/* eslint-disable camelcase */
import { getUserInformation } from "src/utils";
import { FormField } from "..";
import { JiraIssueRequest } from "src/hooks/useCreateIssue";
import { JiraConfig } from "src/pages/growth/jiraRouting";

export const createJiraDetails = (formState: FormField): string => {
  const userInfo = getUserInformation();
  const {
    selectedCompany,
    opportunity_id,
    solconDetail,
    contacts,
    industry,
    sandboxType,
    motivation,
  } = formState;

  // Issue description
  return `
        *Reporter*: ${userInfo?.email}
        *Company*: ${selectedCompany?.ACCOUNT_NAME}
        *Opportunity*: ${opportunity_id}
        *Sandbox Type*: ${sandboxType}
        *Motivation/Request*: ${motivation}
        *Solutions Consultant Detail*: ${solconDetail?.SOLCON_NAME} - ${
    solconDetail?.SOLCON_EMAIL
  }
        *Industry*: ${industry}
        *Contact*: \n${contacts
          .map((c) => `* ${c.name} - ${c.email}`)
          .join("\n")}
        `;
};

// Create jira issue
export const createJiraIssueReq = (
  jiraConfig: JiraConfig,
  formState: FormField
): JiraIssueRequest => {
  const issueData = createJiraDetails(formState);
  const jiraIssueRequest: JiraIssueRequest = {
    fields: {
      project: {
        key: jiraConfig.jiraProjectKey,
      },
      description: issueData,
      summary: `Sandbox Dashboard Request - ${formState.selectedCompany?.ACCOUNT_NAME}`,
      issuetype: {
        name: "Task",
      },
      customfield_10000: jiraConfig.epicLink,
      labels: jiraConfig.labels,
    },
  };
  return jiraIssueRequest;
};
